<script setup>
import { PhxIcon } from '@phx-styleguides/pia';
import { useI18n } from 'vue-i18n';
import { computed, onMounted, ref, useSlots } from 'vue';
import { useRecentTerms } from '@maison/composables/recentTerms';
import AlgoliaSearch, { algoliaSearch } from '../../search/algolia/AlgoliaSearch';
import Product from '@maison/components/common/Product.vue';
import PictureLazy from '@maison/components/common/PictureLazy.vue';
import { IndexNames } from '@module/Algolia/resources/js/search/AlgoliaSearch';
import { algoliaTracking } from '@maison/search/algolia/helpers';
import upperFirst from 'lodash-es/upperFirst';
import BoxedLoader from '@maison/components/common/BoxedLoader.vue';

const props = defineProps({
  allBoutiquesImage: {
    type: Object,
    required: true,
  },
  hitsPerType: {
    type: Object,
    default: () => ({}),
  },
  maxRecentSearch: {
    type: Number,
    default: 5,
  },
});

const emit = defineEmits(['term-clicked']);

const { t } = useI18n();
const slots = useSlots();
const { clearRecentTerms, recentTerms } = useRecentTerms('mainNavigation', props.maxRecentSearch);
const products = ref([]);
const collections = ref([]);
const suggestions = ref([]);

const boutiquesUrl = window.route('boutiques');
const isAlgoliaEnabled = AlgoliaSearch.isEnabled();
const isProductsEmpty = computed(() => !products.value?.length);
const isCollectionsEmpty = computed(() => !collections.value?.length);
const isSuggestionsEmpty = computed(() => !suggestions.value?.length);
const isRecentTermsEmpty = computed(() => !recentTerms.value?.length);
const isAlgoliaLoading = ref(isAlgoliaEnabled);

const startCase = (term) => term.split(' ').map(upperFirst).join(' ');

const onTermClick = (term) => {
  emit('term-clicked', term);
};

const onItemClick = (item, position, index) => {
  algoliaTracking(item, position, index, 'empty-state');
};

onMounted(async () => {
  if (isAlgoliaEnabled) {
    const data = await algoliaSearch.loadEmptyState(props.hitsPerType);
    products.value = data?.products ?? [];
    collections.value = data?.collections ?? [];
    suggestions.value = data?.suggestions ?? [];
    isAlgoliaLoading.value = false;
  }
});
</script>

<template>
  <div>
    <boxed-loader v-show="isAlgoliaLoading" />
    <div class="default-search-results">
      <section class="default-search-results__section default-search-results__tags">
        <nav v-if="!isRecentTermsEmpty" class="tags-nav" aria-labelledby="tags-nav-recent-title" data-simplebar>
          <h3 id="tags-nav-recent-title" class="tags-nav__title">{{ t('SearchPanel.recent') }}</h3>
          <ul class="tags-nav__list">
            <li v-for="(term, index) in recentTerms" :key="index" class="tags-nav__item">
              <button class="tag tag--clickable" @click="onTermClick(term)">{{ startCase(term) }}</button>
            </li>
            <li class="tags-nav__item">
              <button class="btn btn--bare btn--ghost btn--ghost-end-icon" @click="clearRecentTerms">
                {{ t('SearchPanel.clear') }}
                <span class="btn__icon btn__icon--end">
                  <phx-icon icon="close" size="xs" />
                </span>
              </button>
            </li>
          </ul>
        </nav>
        <nav v-if="!isSuggestionsEmpty" class="tags-nav" aria-labelledby="tags-nav-popular-title" data-simplebar>
          <h3 id="tags-nav-popular-title" class="tags-nav__title">{{ t('SearchPanel.popular') }}</h3>
          <ul class="tags-nav__list">
            <li v-for="(suggestion, index) in suggestions" :key="index" class="tags-nav__item">
              <button class="tag tag--clickable" @click="onTermClick(suggestion.term)">
                {{ startCase(suggestion.term) }}
              </button>
            </li>
          </ul>
        </nav>
      </section>

      <section v-show="!isProductsEmpty" class="default-search-results__section">
        <h2 class="default-search-results__title">{{ t('SearchPanel.products.title') }}</h2>
        <div class="mixed-grid mixed-grid--mobile-scrollable" data-simplebar>
          <div
            v-for="(product, index) in products"
            :key="product.modelReference"
            class="mixed-grid__item mixed-grid__item--2/5 md:mixed-grid__item--1/2 lg:mixed-grid__item--1/4"
          >
            <product
              v-bind="product"
              label=""
              description=""
              small-card
              :should-display-add-to-wishlist="false"
              :disable-wide-style="false"
              @click="onItemClick(product, index, IndexNames.Products)"
            />
          </div>
        </div>
      </section>

      <section v-show="!isCollectionsEmpty" class="default-search-results__section">
        <h2 class="default-search-results__title">{{ t('SearchPanel.collections.title') }}</h2>
        <div class="mixed-grid mixed-grid--mobile-scrollable" data-simplebar>
          <div
            v-for="(collection, index) in collections"
            :key="collection.modelReference"
            class="mixed-grid__item mixed-grid__item--2/5 md:mixed-grid__item--1/2 lg:mixed-grid__item--1/4"
          >
            <product
              :description="collection.description"
              :image="collection.rokkaImg"
              :name="collection.title"
              :url="collection.link"
              :should-display-add-to-wishlist="false"
              :disable-wide-style="false"
              :should-display-hover-image="false"
              :should-display-from-price="false"
              model-reference=""
              sap-reference=""
              small-card
              should-hide-price
              @click="onItemClick(collection, index, IndexNames.Collections)"
            />
          </div>
        </div>
      </section>

      <section class="default-search-results__section default-search-results__boutiques">
        <h2 class="default-search-results__title">{{ t('SearchPanel.boutiques.title') }}</h2>
        <div class="media-card">
          <div class="picture media-card__media picture--block">
            <picture-lazy v-bind="allBoutiquesImage" />
          </div>
          <div class="media-card__actions media-card__actions--left">
            <a
              :href="boutiquesUrl"
              class="btn btn--primary btn--negative expand-target w-full md:w-auto"
              :aria-label="t('SearchPanel.boutiques.cta.title')"
            >
              {{ t('SearchPanel.boutiques.cta.title') }}
            </a>
          </div>
        </div>
      </section>

      <section v-if="slots.contacts" class="default-search-results__section default-search-results__questions">
        <h2 class="default-search-results__title">{{ t('SearchPanel.contacts.title') }}</h2>
        <ul class="list list--stacked list--md list--divided link-list">
          <slot name="contacts" />
        </ul>
      </section>
    </div>
  </div>
</template>
