import { reactive, toRef } from 'vue';

const store = reactive({});

export const useRecentTerms = (namespace = null, limitTerms = 5) => {
  const key = namespace ? `recentTerms.${namespace}` : 'recentTerms';

  store[key] = retrieveValue(key, limitTerms);

  const recentTerms = toRef(() => store[key]);

  const addRecentTerm = (term: string) => {
    const sanitizedTerm = term.trim();

    if (!sanitizedTerm || store[key].includes(sanitizedTerm)) {
      return;
    }

    store[key].unshift(sanitizedTerm);

    if (store[key].length > limitTerms) {
      store[key].length = limitTerms;
    }

    storeValue(key, store[key]);
  };

  const clearRecentTerms = () => {
    store[key] = [];
    localStorage.removeItem(key);
  };

  return { recentTerms, addRecentTerm, clearRecentTerms };
};

const retrieveValue = (key: string, limitTerms = 5) => {
  const terms = JSON.parse(localStorage.getItem(key)) ?? [];
  if (terms.length > limitTerms) {
    return terms.slice(0, limitTerms);
  }

  return terms;
};
const storeValue = (key: string, value: string[]) => localStorage.setItem(key, JSON.stringify(value));
